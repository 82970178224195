
$app-desktop-height: 700px
$app-desktop-width-breakpoint: 500px

:root
  --fygi-blue: #0A1237
  --fygi-green: #29B937
  --fygi-red: #EE3226
  /**
   * Themeable colors
   */
  --primary-color: var(--fygi-blue)
  --primary-text-color: white /* Complementary to --primary-color */
  --background-color: #f4f4f4

html, body
  width: 100%
  height: 100%
  margin: 0
  font-family: 'Gilroy', sans-serif !important
  font-weight: normal

html:not(.cdk-global-scrollblock)
  overflow-x: hidden
  body
    overflow-x: hidden

  &::-webkit-scrollbar
    display: none

@media only screen and (min-width: $app-desktop-width-breakpoint)
  body
    position: absolute
    left: calc(50% - 187.5px)
    top: 20px
    height: $app-desktop-height
    width: 375px
    box-shadow: 5px 7px 20px -2px hsl(0deg 0% 53%)
    -ms-overflow-style: none  /* IE and Edge */
    scrollbar-width: none  /* Firefox */

.swiper-pagination-bullet
  margin: 0 2px !important
  opacity: 50% !important
  background: white
  border: solid 1px #00000050

.swiper-pagination-bullet-active
  opacity: 100% !important
  background: white

.no-select
  -webkit-touch-callout: none /* iOS Safari */
  -webkit-user-select: none /* Safari */
   -khtml-user-select: none /* Konqueror HTML */
     -moz-user-select: none /* Old versions of Firefox */
      -ms-user-select: none /* Internet Explorer/Edge */
          user-select: none /* Non-prefixed version */

mat-icon
  @extend .no-select

.drop-shadow
  box-shadow: 1px 0 5px -3.5px #AAA8
  margin-top: 1px

[scrollState]
  z-index: 10
  overflow: auto
  transition: box-shadow 125ms ease-in-out

  $topShadow: inset 0px 5px 5px rgba(191, 196, 214, 0.15)
  $topShadowTransparent: inset 0px 5px 5px transparent
  $bottomShadow: inset 0px -5px 5px rgba(191, 196, 214, 0.3)
  $bottomShadowTransparent: inset 0px -5px 5px transparent

  &.can-scroll-up
    box-shadow: $topShadow, $bottomShadowTransparent

  &.can-scroll-down
    box-shadow: $topShadowTransparent, $bottomShadow

  &.can-scroll-up.can-scroll-down
    box-shadow: $topShadow, $bottomShadow

.mat-bottom-sheet-container.full-screen-bottom-sheet
  max-height: 98vh
  border-radius: 25px 25px 0 0

.spacer
  flex-grow: 1
